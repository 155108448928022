jQuery(function($) {

    /* menuAnimation */

    var menuAnimation = gsap.timeline({
        paused:true,
        reversed:true,
        ease: "power3.inOut",
        onComplete : function(){
            document.body.classList.add("nav-is-open");
        },
        onReverseComplete : function(){
            document.body.classList.remove("nav-is-open");
        }
    });

    menuAnimation.from(".overlayNavWrapper", {duration: .3, autoAlpha: 0})
                 .from("header nav#header", {duration: .3, x: 20 }, "<");

    /* toggle nav */

    const menuButtons = document.querySelectorAll(".navTrigger");

    menuButtons.forEach(function(button) {
        button.addEventListener("click", () => {
            menuAnimation.reversed() ? menuAnimation.timeScale(1).play() : menuAnimation.timeScale(1.4).reverse();
        });
    });

    const menuOverlay = document.querySelector(".overlayNavWrapper");

    menuOverlay.addEventListener("click", () => {
        if (!event.target.classList.contains('overlayNavWrapper')) return;
        menuAnimation.reversed() ? menuAnimation.timeScale(1).play() : menuAnimation.timeScale(1.4).reverse();
    });

});
