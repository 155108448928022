jQuery(function($) {
    if ($('.headerSlider').length) {

        $(".headerSlider .swiper-container").each(function(index, element){
            var $this = $(element);
            mySwiper = new Swiper(element, {
                loop: true,
                pagination: {
                    el: $this.parent().find(".swiper-pagination")[0],
                    clickable: true
                },
            });
        });

    }
});
