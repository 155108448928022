var netNav = {

    config : {
        container : $('#netNav'),
        trigger : $('.netNavTrigger'),
        breakpoint : 1000
    },

    mobile: false,

    init: function (config) {

        // provide for custom configuration via init()
        if (config && typeof(config) == 'object') {
            $.extend(netNav.config, config);
        }

        // create and/or cache some DOM elements we'll want to use throughout the code
        netNav.container = netNav.config.container;
        netNav.trigger = netNav.config.trigger;
        netNav.breakpoint = netNav.config.breakpoint;
        netNav.anchorLinks = netNav.container.find('a');

        // add level and hasChildren classes to ul and li elements
        netNav.addClasses(netNav.container.children('ul'));

        //
        $.each(netNav.anchorLinks, function() {
            var link = $(this);

            if(link.siblings('ul').length) {

                // a link with an ul as sibling
                $(this)

                // use data() to store a reference to the sub sublevel
                .data('sub', link.siblings('ul'))

                // use data() to store a reference to the link topmost parent ul
                // ToDo: Check bubbling and use stop propagation if needed
                .data('parent', link.parent('li'))

                // bind the click behavior to the link
                .click(netNav.showSubLevel);

            }
        });

        // hide sublevels
        netNav.hideAllSubLevels();

        // add events
        netNav.addEvents();

        // check screensize
        netNav.checkScreenSize();

    },

    /*
     * add level classes to all ul elements
     */

    addClasses: function(ul, level) {

        level = (typeof level !== 'undefined') ? level : 1;

        // add a class to all ul elements on this level:
        ul.addClass('level' + level);

        // find any child <ul> elements and run this function on them
        if (ul.children('li').children('ul').length) {
            // add hasChildren class to parent li
            ul.children('li').children('ul').parent().addClass('hasChildren');
            netNav.addClasses(ul.children('li').children('ul'), level + 1);
        }

    },

    /*
     * hide all sub menus
     */

    hideAllSubLevels: function() {

        netNav.container.find('ul:not(.level1)').addClass('hidden');
        netNav.container.find('.open').removeClass('open');
        netNav.container.find('.active').removeClass('active');

    },

    /*
     * hide only sibling sub menus
     * needed when switching from one main branch to another
     */

    hideSiblingSubLevels: function( element ) {

        element.siblings().find('ul').addClass('hidden');
        element.siblings().removeClass('open').removeClass('active');
        element.siblings().find('.open').removeClass('open');

    },

    /*
     * show the targeted sub menu
     */

    showSubLevel : function() {

        // capture the anchor item that was clicked on
        var anchor = $(this);

        // get the sub ul which we stored using data()
        var sub = anchor.data('sub');

        // get the parent element (li) which we stored using data
        var parent = anchor.data('parent');

        // remove the active marker from all other anchors
        // mark the clicked nav item as active
        netNav.anchorLinks.not(anchor).parent().removeClass('active');
        anchor.parent('li').toggleClass('active');

        // mark the clicked nav item as open
        anchor.parent('li').toggleClass('open');

        // show the sub
        sub.toggleClass('hidden');

        netNav.hideSiblingSubLevels(parent);

    },

    toggle: function () {
        netNav.container.toggleClass('show');
        netNav.trigger.toggleClass('active');
        //console.log('toggle');
    },

    hide: function () {
        netNav.container.removeClass('show');
        netNav.trigger.removeClass('active');
    },

    checkScreenSize: function () {
        if ( $(window).innerWidth() < netNav.config.breakpoint ) {
            netNav.container.addClass('mobile');
            netNav.hideAllSubLevels();
            if(!netNav.mobile) {
                netNav.mobile = true;
                netNav.container.removeClass('desktop');
            }
        } else {
            netNav.container.addClass('desktop');
            netNav.hideAllSubLevels();
            if(netNav.mobile) {
                netNav.mobile = false;
                netNav.container.removeClass('mobile');
            }
        }
    },

    addEvents: function() {
        netNav.eventInitDebouncer();
        netNav.eventInitCloseNav();
        netNav.eventInitHideMobileNav();
    },

    removeEvents: function() {

    },

    /*
     * EVENTS
     */

    eventInitDebouncer: function () {
        $(window).resize(function() {
            clearTimeout(window.resizedFinished);
            window.resizedFinished = setTimeout(function(){
                netNav.checkScreenSize();
            }, 30);
        });
    },

    eventInitCloseNav: function() {
        $('html').click(function(event) {
            if ($(event.target).closest(netNav.container).length === 0 && $(event.target).closest(netNav.trigger).length === 0) {
                netNav.hideAllSubLevels();
                if ( netNav.mobile ) {
                    netNav.hide();
                }
            }
        });
    },

    eventInitHideMobileNav: function() {
        $(netNav.trigger).click(function(event) {
            netNav.hideAllSubLevels();
            netNav.toggle();
        });
    }
};
