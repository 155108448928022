const gameReports = document.querySelectorAll('.gameReports');
if ($('.gameReports .hidden').length) {
    gsap.set( '.gameReports .hidden' , { height: 0 } );
}

gameReports.forEach(function(element) {
    element.addEventListener('click', function(e) {

        if (!e.target.closest('.headline')) return;

        const report = event.target.closest('.news');
        const headline = event.target.closest('.headline');
        const content = headline.nextElementSibling;

        if (!report.classList.contains('expanded')) {
            report.classList.add('expanded');
            gsap.set( content,  { height: "auto" } );
            gsap.from( content, .2,  { height: 0, ease: Sine.easeInOut} );
        } else {
            report.classList.remove('expanded');
            gsap.to( content, .2,  { height: 0, ease: Sine.easeInOut} );
        }

    });
});

var page = 2;

$('.showMore button').click(function(){

    var form = $('#load_more');
    var data = form.serializeArray();
    var button = $(this);
    var pages = form.data('pages');

    data.push({'name' : 'page', 'value': page});

    $.ajax({
        url :  form.attr('action'),
        data : data,
        type: form.attr('method'),
        beforeSend : function ( xhr ) {
            button.text('Loading...');
        },
        success : function( result ){
            if( result ) {

                button.text('mehr anzeigen');

                $('.newsList').append(result);

                if ( page == pages )
                    form.remove();

                page++;

                $('#page').val(page);

            } else {
                form.remove();
            }
        }
    });

    return false;
});
