jQuery(function($) {
    if ($('.adSlider').length) {

        $(".adSlider .swiper-container").each(function(index, element){
            var $this = $(element);
            mySwiper = new Swiper(element, {
                loop: true,
                autoplay: {
                    delay: 5000,
                },
            });
        });

    }
});
