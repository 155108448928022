jQuery(function($) {
    if ($('.fullwidth .imageGallery').length) {

        $(".fullwidth .imageGallery .swiper-container").each(function(index, element){
            var $this = $(element);
            mySwiper = new Swiper(element, {
                loop: true,
                slidesPerView: 1,
                spaceBetween: 0,

                navigation: {
                    nextEl: $this.parent().find(".swiper-button-next")[0],
                    prevEl: $this.parent().find(".swiper-button-prev")[0],
                },
                breakpoints: {
                    500: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    750: {
                        slidesPerView: 3,
                        spaceBetween: 15,
                    },
                    1000: {
                        slidesPerView: 4,
                        spaceBetween: 15,
                    },
                    1300: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                    },
                    1700: {
                        slidesPerView: 4,
                        spaceBetween: 60
                    }
                }
            });
        });

    }

    if ($('main .imageGallery').length) {

        $("main .imageGallery .swiper-container").each(function(index, element){
            var $this = $(element);
            mySwiper = new Swiper(element, {
                loop: true,
                slidesPerView: 1,
                spaceBetween: 0,

                navigation: {
                    nextEl: $this.parent().find(".swiper-button-next")[0],
                    prevEl: $this.parent().find(".swiper-button-prev")[0],
                },
                breakpoints: {
                    500: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    750: {
                        slidesPerView: 3,
                        spaceBetween: 15,
                    },
                    1300: {
                        slidesPerView: 3,
                        spaceBetween: 25,
                    }
                }
            });
        });

    }
});
